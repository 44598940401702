
.main-content-container {
    text-align: center;
}

.main-container-template-asset {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
}